import { PlusSolid } from '@motion/icons'
import { useWillExceedWorkspaceFeatureCap } from '@motion/web-billing'

import { FlowTemplateCard, useFlowTemplateModalUrl } from '~/areas/flows'
import { MotionLink } from '~/global/components'
import { WorkspaceDropdown } from '~/global/components/dropdowns'
import { useOpenFlowsAiModal } from '~/global/hooks'
import { type PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

export type NewFlowTemplateLinkProps = {
  workspaceId: string | undefined
  onClose?: () => void
  onFeatureGated?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const NewFlowTemplateLink = ({
  workspaceId,
  onClose,
  children,
}: PropsWithChildren<NewFlowTemplateLinkProps>) => {
  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()
  const canCreateFlowTemplate = !useWillExceedWorkspaceFeatureCap({
    feature: 'projectDefinitions',
    workspaceId,
    addedUsage: 1,
  })

  if (workspaceId == null) {
    return (
      <FlowTemplateWorkspaceDropdown onClose={onClose}>
        {children}
      </FlowTemplateWorkspaceDropdown>
    )
  }

  if (!canCreateFlowTemplate) {
    return children
  }

  const newFlowTemplateUrl = buildFlowTemplateModalUrl({
    forWorkspace: workspaceId,
    template: 'new',
  })

  return (
    <MotionLink
      url={newFlowTemplateUrl}
      title='Create a new project workflow template'
      className='inline-flex'
    >
      {children}
    </MotionLink>
  )
}

export const NewFlowTemplateCardLink = ({
  workspaceId,
  onFeatureGated,
}: NewFlowTemplateLinkProps) => {
  return (
    <NewFlowTemplateLink workspaceId={workspaceId}>
      <button className='w-full' onClick={onFeatureGated}>
        <FlowTemplateCardContainer fullSize />
      </button>
    </NewFlowTemplateLink>
  )
}

const FlowTemplateCardContainer = ({ fullSize }: { fullSize: boolean }) => {
  return (
    <FlowTemplateCard
      className={twMerge(
        'items-center justify-center gap-4',
        fullSize && 'w-full h-[250px]'
      )}
    >
      <PlusSolid className='h-7 w-7 text-semantic-neutral-icon-default' />
      <h3 className='font-semibold text-sm truncate text-semantic-neutral-text-default'>
        New Project Workflow Template
      </h3>
    </FlowTemplateCard>
  )
}

const FlowTemplateWorkspaceDropdown = ({
  children,
  onClose,
}: {
  children: React.ReactNode
  onClose?: () => void
}) => {
  const openFlowsAiModal = useOpenFlowsAiModal()

  return (
    <WorkspaceDropdown
      selectedWorkspaceId=''
      onChange={(workspace) => {
        openFlowsAiModal(workspace.id, onClose)
      }}
    >
      {children}
    </WorkspaceDropdown>
  )
}
