import { createUseMutation, createUseQuery } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'

export const useAgentWorkflows = createUseQuery(
  API.agentWorkflows.getAgentWorkflows,
  {
    select: (data) => {
      return data.ids.map((id) => data.models[data.meta.model][id])
    },
  }
)

export const useAgentWorkflowById = createUseQuery(
  API.agentWorkflows.getAgentWorkflowById,
  {
    select: (data) => {
      return data.models[data.meta.model][data.id]
    },
  }
)

export const useCreateAgentWorkflow = createUseMutation(
  API.agentWorkflows.createAgentWorkflow
)
