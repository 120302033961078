import { PopoverButton } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { createLookup } from '@motion/utils/object'
import {
  type AgentVariableType,
  type AgentWorkflowInitVariableSchema,
  type AgentWorkflowListingSchema,
} from '@motion/zod/client'

import {
  WorkspaceDropdown,
  WorkspacesTreeDropdown,
} from '~/global/components/dropdowns'
import { ConnectedWorkspaceLabel } from '~/global/components/labels'
import { type ReactNode, useState } from 'react'

import { WorkflowField } from './workflow-field'

import { SectionField } from '../components'

export type FieldsetContentProps = {
  workflows: AgentWorkflowListingSchema[]
  modalWorkflowId?: AgentWorkflowListingSchema['id']
}

export function FieldsetContent({
  workflows,
  modalWorkflowId,
}: FieldsetContentProps) {
  const [selectedWorkflowId, setSelectedWorkflowId] = useState(
    modalWorkflowId ?? workflows[0]?.id
  )
  const selectedWorkflow = workflows.find((w) => w.id === selectedWorkflowId)

  return (
    <>
      {modalWorkflowId == null && (
        <WorkflowField
          workflows={workflows}
          selectedWorkflow={selectedWorkflow}
          onSelect={setSelectedWorkflowId}
        />
      )}

      {selectedWorkflow?.variables.map((workflowVariable) => {
        // TODO tleunen - type shouldn't be optional
        const field = variableFieldLookup(workflowVariable.type ?? 'TEXT')(
          workflowVariable
        )

        return (
          <SectionField
            key={workflowVariable.scopeId + workflowVariable.id}
            title={workflowVariable.label}
            subTitle={workflowVariable.description}
          >
            {field}
          </SectionField>
        )
      })}
    </>
  )
}

type VariableMapper = {
  [P in AgentVariableType]: (
    value: AgentWorkflowInitVariableSchema
  ) => ReactNode
}

const variableFieldLookup = createLookup<
  VariableMapper & {
    default: () => ReactNode
  }
>({
  TEXT: (value) => {
    return (
      <TextField
        multiline
        label={value.label}
        labelHidden
        value={value.defaultValue}
        onChange={() => {}}
      />
    )
  },
  DOCUMENTS: (value) => {
    return (
      <WorkspacesTreeDropdown
        selectedId={value.defaultValue ?? ''}
        // workspaceId={''}
        onChange={(selected) => {}}
        includeNotes
        leafNodeType='NOTE'
      >
        <PopoverButton>Pick a note</PopoverButton>
      </WorkspacesTreeDropdown>
    )
  },
  LOCATION: (value) => {
    return (
      <WorkspaceDropdown
        onChange={(workspace) => {}}
        selectedWorkspaceId={null}
        withAllWorkspacesOption
      >
        <PopoverButton>
          <ConnectedWorkspaceLabel id='' />
        </PopoverButton>
      </WorkspaceDropdown>
    )
  },

  default: () => 'unknown',
})
