import { sanitizeAgentLabel } from '@motion/shared/agents'
import {
  type AgentWorkflowStepSchema,
  type AgentWorkflowStepType,
} from '@motion/zod/client'

export function createWorkflowVariableId(name: string) {
  return `workflow.${sanitizeAgentLabel(name)}`
}

export function createStepId(stepName: string) {
  return `${sanitizeAgentLabel(stepName)}`
}

export function createStepVariableId(
  stepName: string,
  variableName = '__output'
) {
  return `${createStepId(stepName)}.${sanitizeAgentLabel(variableName)}`
}

export function convertStepType(
  step: AgentWorkflowStepSchema,
  stepType: AgentWorkflowStepType
): AgentWorkflowStepSchema {
  const newStep: AgentWorkflowStepSchema = {
    ...step,
    type: stepType,
    variables: [],
  }

  if (stepType === 'CREATE_DOCUMENT') {
    newStep.variables = [
      {
        type: 'LOCATION',
        id: createStepVariableId(step.name, 'Doc location'),
        label: 'Doc location',
        description: 'Location where the document will be created',
        required: true,
      },
    ]
  }

  return newStep
}

export function updateStepIdInStep(
  step: AgentWorkflowStepSchema
): AgentWorkflowStepSchema {
  if (step.name.trim().length === 0) return step

  const stepId = createStepId(step.name)

  return {
    ...step,
    id: stepId,
    variables: step.variables.map((v) => {
      return {
        ...v,
        id: createStepVariableId(step.name, v.label),
      }
    }),
  }
}
