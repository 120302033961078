import { useOnDesktopEvent } from '@motion/desktop-ipc/hooks'
import { MotionCache } from '@motion/rpc-cache'
import { useShortcut } from '@motion/ui/base'
import { ConditionalWrapper } from '@motion/ui/utils'
import { isMeetingTask, type TaskFormFields } from '@motion/ui-logic/pm/task'
import { useOnMountAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalTitle } from '@motion/web-common/html'
import { userSettingsQueryFilter } from '@motion/web-common/settings'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

import { AutoSaveProvider } from '~/areas/task-project/contexts'
import { useTrackEntityOpen } from '~/global/hooks'
import { useSubcribeToFeed } from '~/global/hooks/websockets'
import {
  useNavigateByGlobalModalId,
  useRouteConfirmationPromptBeforeLeaving,
  useUnsavedChangesPrompt,
} from '~/global/navigation'
import { type ReactNode, useEffect } from 'react'

import {
  TaskModalStateProvider,
  type TaskModalStateProviderProps,
  useTaskModalState,
} from './contexts'
import {
  type InitialTaskData,
  useInitialTaskDataFromLocation,
  useTaskForm,
  useUrlTaskInitialFormData,
} from './hooks'
import { TaskForm } from './task-form'
import {
  TaskModalContent,
  type TaskModalContentProps,
} from './task-modal-content'
import { type FormDataSubmitHandler } from './types'

import { useCreateTask, useTaskUpdater } from '../../hooks'

type ConnectedTaskModalBodyProps = {
  close: () => void
  onTaskCreated: (task: TaskSchema | RecurringTaskSchema) => void
  fromDesktopOptionSpace?: boolean
}

export function ConnectedTaskModalBody({
  close,
  onTaskCreated,
  fromDesktopOptionSpace = false,
}: ConnectedTaskModalBodyProps) {
  const initialTaskData = useInitialTaskDataFromLocation()
  const navigateToModal = useNavigateByGlobalModalId()
  const initialFormData = useUrlTaskInitialFormData(initialTaskData)

  const createTask = useCreateTask()
  const updateTask = useTaskUpdater()

  const taskId = initialFormData.id

  useOnMountAnalyticsEvent('PROJECT_MANAGEMENT_VIEW_TASK', {
    enabled: taskId != null,
  })
  useOnMountAnalyticsEvent('TASK_OPEN', {
    properties: { desktopOptionSpace: fromDesktopOptionSpace },
  })
  useTrackEntityOpen({ id: taskId, type: 'TASK' })
  useModalTitle(taskId ? initialFormData.name : 'New task')

  const isMeeting = isMeetingTask(initialTaskData.task)

  useEffect(() => {
    if (!isMeeting || initialTaskData.task?.id == null) {
      return
    }

    navigateToModal('meeting', { mTask: initialTaskData.task.id })
  }, [isMeeting, initialTaskData.task?.id, navigateToModal])

  if (!initialTaskData.isLoading && isMeeting) {
    return null
  }

  const onFormSubmit: FormDataSubmitHandler = async ({ type, data }) => {
    if (type === 'create') {
      const task = await createTask(data)
      onTaskCreated(task)
      return
    }

    // The following "update path" is implemented but in reality, it's a code path that is never reached.
    // A connected task modal is currently always in an autosave mode, so `onFormSubmit` is only called in a "create" mode
    // The only way to go here is by setting `options={{isAutoSaving: false}}` to the `TaskModalBody`
    if (taskId == null) {
      throw new Error('task id is not defined with an update submit type')
    }

    await updateTask(taskId, data)
    return
  }

  return (
    <TaskModalBody
      initialTaskData={initialTaskData}
      initialFormData={initialFormData}
    >
      {fromDesktopOptionSpace ? (
        <OptionSpaceTaskModalContent
          close={close}
          onFormSubmit={onFormSubmit}
        />
      ) : (
        <InAppTaskModalContent close={close} onFormSubmit={onFormSubmit} />
      )}
    </TaskModalBody>
  )
}

export type TaskModalBodyProps = {
  initialTaskData: InitialTaskData
  initialFormData: TaskFormFields
  options?: TaskModalStateProviderProps['options']
  children: ReactNode
}
export function TaskModalBody({
  initialTaskData,
  initialFormData,
  options,
  children,
}: TaskModalBodyProps) {
  return (
    <ConditionalWrapper
      condition={options?.isAutoSaving ?? true}
      wrapper={(children) => (
        <AutoSaveProvider suppressSaveToast>{children}</AutoSaveProvider>
      )}
    >
      <TaskModalStateProvider
        initialTaskData={initialTaskData}
        options={options}
      >
        <TaskForm initialFormData={initialFormData}>{children}</TaskForm>
      </TaskModalStateProvider>
    </ConditionalWrapper>
  )
}

const OptionSpaceTaskModalContent = ({
  close: parentClose,
  onFormSubmit,
}: TaskModalContentProps) => {
  const { form } = useTaskForm()
  const promptUnsavedChanges = useUnsavedChangesPrompt()
  const {
    formState: { isDirty, isSubmitting },
  } = form

  const hasDirtyFormFields = isDirty && !isSubmitting

  const close = async () => {
    const proceed = hasDirtyFormFields ? await promptUnsavedChanges() : true

    if (proceed) {
      parentClose()
    }
  }

  useShortcut('escape', () => close())

  useOnDesktopEvent('updateUserSettings', (settings) => {
    // @ts-expect-error: Need to align the types of the desktop and web user settings
    MotionCache.upsert(client, userSettingsQueryFilter, settings)

    // In desktop app, when the base window settings are updated, we need to reset the form
    if (!hasDirtyFormFields) {
      form.reset()
    }
  })

  return (
    <div className='contents [&_form]:max-w-none'>
      <TaskModalContent close={close} onFormSubmit={onFormSubmit} />
    </div>
  )
}

export const InAppTaskModalContent = ({
  close,
  onFormSubmit,
}: TaskModalContentProps) => {
  const { form } = useTaskForm()
  const { hasPendingComment } = useTaskModalState()
  const {
    watch,
    formState: { isDirty, isSubmitting },
  } = form

  const taskId = watch('id')

  useSubcribeToFeed('task', taskId)

  const hasDirtyFormFields = isDirty && !isSubmitting
  const isNotAutoSaved = taskId == null

  useRouteConfirmationPromptBeforeLeaving({
    when: (hasDirtyFormFields && isNotAutoSaved) || hasPendingComment,
    title:
      hasPendingComment && !hasDirtyFormFields
        ? 'Your comment has not been saved'
        : undefined,
  })

  return <TaskModalContent close={close} onFormSubmit={onFormSubmit} />
}
