import { useWillExceedWorkspaceFeatureCap } from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'
import { type WorkspaceSchema } from '@motion/zod/client'

export const useFeatureGatedAddCustomField = <T extends unknown[]>(
  workspace: Pick<WorkspaceSchema, 'name' | 'id'> | undefined | null,
  onAdd: (...args: T) => void
) => {
  const canAddCustomField = !useWillExceedWorkspaceFeatureCap({
    feature: 'customFields',
    workspaceId: workspace?.id,
    addedUsage: 1,
  })
  const modalApi = useModalApi()

  return [
    canAddCustomField,
    (...args: T) => {
      if (!workspace) {
        return
      }

      if (canAddCustomField) {
        onAdd(...args)
      } else {
        modalApi.open('tier-upgrade-prompt-modal', {
          tier: 'PRO',
          feature: 'customFields',
        })
      }
    },
  ] as const
}
