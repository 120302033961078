import { PlusSolid, ProjectCubeSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { ButtonGroup } from '@motion/ui/base'
import { byProperty, Compare } from '@motion/utils/array'
import { useWillExceedWorkspaceFeatureCap } from '@motion/web-billing'

import { FlowTutorialButton } from '~/areas/flows'
import { TieredPricingUpsellButton } from '~/areas/tiered-pricing'
import {
  useOpenFlowsAiModal,
  useProjectDefinitionsWithProjectInfo,
  useWorkspaceLegacyProjectTemplates,
} from '~/global/hooks'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { FlowTemplateCard } from './flow-template-card'
import { LegacyTemplateCard } from './legacy-template-card'
import { SettingsFlowsAICard } from './settings-flows-ai-card'
import {
  FlowOrStageCardSection,
  FlowSettingsTable,
  SettingsTitle,
  SettingsTitleDescription,
  SettingsTitleText,
} from './styled'

export function FlowTemplateTable() {
  const { workspaceId = '' } = useParams<{ workspaceId: string }>()
  const flowProjectTemplates = useProjectDefinitionsWithProjectInfo(workspaceId)
  const nonFlowTemplates = useWorkspaceLegacyProjectTemplates(workspaceId)
  const canCreateProjectTemplate = !useWillExceedWorkspaceFeatureCap({
    feature: 'projectDefinitions',
    workspaceId,
    addedUsage: 1,
  })
  const flowTemplatesByName = useMemo(() => {
    return flowProjectTemplates.toSorted(byProperty('name', Compare.string))
  }, [flowProjectTemplates])

  const openFlowsAiModal = useOpenFlowsAiModal()

  return (
    <FlowSettingsTable>
      <SettingsTitle>
        <SettingsTitleText>
          <ProjectCubeSolid /> Project Templates
        </SettingsTitleText>
        <ButtonGroup>
          <FlowTutorialButton rounded={false} />
          <TieredPricingUpsellButton
            feature='projectDefinitions'
            featureLocked={!canCreateProjectTemplate}
            onClick={() => openFlowsAiModal(workspaceId)}
            size='small'
          >
            <PlusSolid /> Create project template
          </TieredPricingUpsellButton>
        </ButtonGroup>
      </SettingsTitle>
      <SettingsTitleDescription>
        {templateStr(
          `A {{projectTemplateLabel}} in Motion is a predefined structure that standardizes project execution by outlining tasks, stages, dependencies, and best practices, ensuring consistency across teams. It functions as an SOP (Standard Operating Procedure) by embedding repeatable workflows directly into project management, reducing manual setup, and minimizing errors.`,
          {
            projectTemplateLabel: (
              <span className='font-bold'>Project Template</span>
            ),
          }
        )}
      </SettingsTitleDescription>
      <SettingsTitleDescription>
        💡 Project workflow templates are defined by stages, which in turn
        contain tasks.
      </SettingsTitleDescription>
      <FlowOrStageCardSection>
        <SettingsFlowsAICard />
        {flowTemplatesByName.map((flowTemplate) => (
          <FlowTemplateCard key={flowTemplate.id} flowTemplate={flowTemplate} />
        ))}
        {nonFlowTemplates.map((legacyTemplate) => (
          <LegacyTemplateCard
            key={legacyTemplate.id}
            legacyTemplate={legacyTemplate}
          />
        ))}
      </FlowOrStageCardSection>
    </FlowSettingsTable>
  )
}
