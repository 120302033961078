import { type AgentWorkflowListingSchema } from '@motion/rpc-types'
import {
  ActionList,
  PopoverButton,
  PopoverTrigger,
  Text,
} from '@motion/ui/base'
import { truncateAtSpace } from '@motion/ui-logic'

import { SectionField } from '../components'

export type WorkflowFieldProps = {
  workflows: AgentWorkflowListingSchema[]
  selectedWorkflow?: AgentWorkflowListingSchema
  onSelect: (workflowId: AgentWorkflowListingSchema['id']) => void
}

export function WorkflowField({
  workflows,
  selectedWorkflow,
  onSelect,
}: WorkflowFieldProps) {
  return (
    <SectionField title='AI Workflow'>
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <WorkflowDropdownContent
            close={close}
            onSelect={onSelect}
            workflows={workflows}
          />
        )}
      >
        <PopoverButton>
          <Text truncate size='sm'>
            {selectedWorkflow?.name}
          </Text>
        </PopoverButton>
      </PopoverTrigger>
    </SectionField>
  )
}

type AgentPickerContentProps = {
  close: () => void
} & WorkflowFieldProps
function WorkflowDropdownContent({
  close,
  onSelect,
  selectedWorkflow,
  workflows,
}: AgentPickerContentProps) {
  return (
    <ActionList
      onActionAnyItem={close}
      items={workflows.map((w) => ({
        content: w.name,
        description: truncateAtSpace(w.description, 100),
        onAction: () => {
          onSelect(w.id)
        },
      }))}
    />
  )
}
