import { ArrowLeftSolid, PlusOutline, PlusSolid } from '@motion/icons'
import { useDependantState } from '@motion/react-core/hooks'
import { createPlaceholderId } from '@motion/shared/identifiers'
import {
  Button,
  ButtonGroup,
  IconButton,
  LoadingSpinner,
  PopoverTrigger,
  showToast,
  Text,
} from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { uniqueId } from '@motion/utils/core'
import { useTitle } from '@motion/web-common/html'
import {
  type AgentVariableSchema,
  type AgentWorkflowSchema,
  type AgentWorkflowStepSchema,
} from '@motion/zod/client'

import { useMyTasksWorkspace } from '~/global/hooks'
import { useRouteConfirmationPromptBeforeLeaving } from '~/global/navigation'
import { useAgentWorkflowById, useCreateAgentWorkflow } from '~/global/rpc'
import { showErrorToast } from '~/global/toasts'
import { useNavigateByRouteId, useUriByRouteId } from '~/routing'
import { Fragment, useState } from 'react'
import { useParams } from 'react-router'

import {
  AgentVariableTag,
  ConnectedWorkflowStep,
  type ConnectedWorkflowStepProps,
  StepLineSeparator,
  WorkflowInputDropdownContent,
  WorkflowSection,
} from './components'
import { type CreateEditPageParams } from './types'
import {
  createStepVariableId,
  createWorkflowVariableId,
  updateStepIdInStep,
} from './utils'

import { Header } from '../../components'

export const CreateEditAIWorkflowPage = () => {
  const { workflowId } = useParams<CreateEditPageParams>()
  const getUri = useUriByRouteId()
  const navigateToUri = useNavigateByRouteId()

  const myTasksWorkspace = useMyTasksWorkspace()
  const workspaceId = myTasksWorkspace?.id ?? ''

  const { mutateAsync: createWorkflow } = useCreateAgentWorkflow()
  const {
    data: workflowData,
    isLoading,
    isError,
  } = useAgentWorkflowById(
    {
      workspaceId,
      workflowId: workflowId ?? '',
    },
    { enabled: !!workflowId }
  )

  const isEditMode = workflowId != null
  const pageTitle = isEditMode
    ? `Edit ${workflowData?.name ?? 'workflow'} `
    : 'New workflow'

  const [dirty, setDirty] = useState(false)
  const [internalWorkflow, setStateWorkflow] = useDependantState(
    () => workflowData ?? createBlankWorkflow(),
    [workflowData],
    { freezeDependencyUpdates: dirty }
  )

  useTitle(pageTitle)
  useRouteConfirmationPromptBeforeLeaving({
    when: dirty,
  })

  const setInternalWorkflow: typeof setStateWorkflow = (value) => {
    setDirty(true)
    setStateWorkflow(value)
  }

  const updateWorkflowField = <
    FieldName extends keyof Omit<AgentWorkflowSchema, 'variables' | 'steps'>,
  >(
    field: FieldName,
    value: AgentWorkflowSchema[FieldName]
  ) => {
    setInternalWorkflow((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const addUpdateInput = (input: AgentVariableSchema | null) => {
    if (input == null) return

    const inputWithId = {
      ...input,
      id: createWorkflowVariableId(input.label),
    }

    setInternalWorkflow((prev) => {
      let modified = false
      const newValue = prev.variables.map((v) => {
        if (v.id === input.id) {
          modified = true
          return inputWithId
        }
        return v
      })

      if (!modified) {
        newValue.push(inputWithId)
      }

      return { ...prev, variables: newValue }
    })
  }

  const removeInput = (inputId: string) => {
    setInternalWorkflow((prev) => {
      return {
        ...prev,
        variables: prev.variables.filter((v) => {
          return v.id !== inputId
        }),
      }
    })
  }

  const addStep = (index: number) => {
    setInternalWorkflow((prev) => {
      return {
        ...prev,
        steps: prev.steps.toSpliced(index, 0, createBlankStep()),
      }
    })
  }

  const updateStep: ConnectedWorkflowStepProps['onChange'] = (updatedStep) => {
    setInternalWorkflow((prev) => {
      return {
        ...prev,
        steps: prev.steps.map((s) => {
          return s.id === updatedStep.id ? updateStepIdInStep(updatedStep) : s
        }),
      }
    })
  }

  const [busy, setBusy] = useState(false)
  const handleClickCreate = async () => {
    try {
      setBusy(true)

      const { id, ...workflowToCreate } = internalWorkflow

      const response = await createWorkflow({
        ...workflowToCreate,
        workspaceId,
        scopeId: workspaceId,
        scopeType: 'WORKSPACE',
      })
      setDirty(false)

      showToast('success', 'Workflow created')
      navigateToUri(
        'ai-workflows-edit',
        { workflowId: response.id },
        { state: { force: true } }
      )
    } catch (e) {
      showErrorToast(e)
    } finally {
      setBusy(false)
    }
  }

  const allStepsVariables = internalWorkflow.steps.flatMap(
    (step) => step.variables
  )

  return (
    <>
      <Header className='justify-between border-b border-tab-border-default'>
        <div className='flex flex-row gap-2 items-center'>
          <IconButton
            icon={ArrowLeftSolid}
            sentiment='neutral'
            variant='muted'
            url={getUri('ai-workflows')}
          />
          <Text truncate>{pageTitle}</Text>
        </div>
        <ButtonGroup>
          {!isEditMode && (
            <Button
              sentiment='primary'
              disabled={isLoading || (isEditMode && dirty)}
              onClick={handleClickCreate}
              loading={busy}
            >
              Create workflow
            </Button>
          )}
          <Button
            sentiment='primary'
            disabled={isLoading || (isEditMode && dirty) || !isEditMode}
          >
            Run workflow
          </Button>
        </ButtonGroup>
      </Header>
      <div className='overflow-auto'>
        {isLoading ? (
          <div className='h-96 grid place-items-center'>
            <LoadingSpinner />
          </div>
        ) : isError ? (
          <div className='h-96 grid place-items-center'>
            <Text weight='semibold'>
              We couldn't load this workflow. Please try again.
            </Text>
          </div>
        ) : (
          <main className='p-10 max-w-2xl mx-auto'>
            <div className='flex flex-col gap-6'>
              <div>
                <TextField
                  autoFocus
                  placeholder='Workflow Name'
                  label='Workflow name'
                  labelHidden
                  size='large'
                  value={internalWorkflow.name}
                  onChange={(v) => updateWorkflowField('name', v)}
                  variant='title'
                />
                <TextField
                  placeholder='Workflow Description'
                  label='Workflow description'
                  labelHidden
                  size='normal'
                  value={internalWorkflow.description}
                  onChange={(v) => updateWorkflowField('description', v)}
                  variant='title'
                />
              </div>
              <WorkflowSection
                title='Inputs'
                description='Inputs provide the necessary context and details the AI needs to
            complete the task accurately. These can include instructions,
            project specs, research topics, or other relevant information.'
              >
                <div className='flex flex-row gap-1 flex-wrap'>
                  {internalWorkflow.variables.map((agentVariable) => {
                    return (
                      <PopoverTrigger
                        key={agentVariable.id}
                        placement='bottom-start'
                        renderPopover={({ close }) => {
                          return (
                            <WorkflowInputDropdownContent
                              close={(value) => {
                                addUpdateInput(value)
                                close()
                              }}
                              agentVariable={agentVariable}
                              onDelete={(id) => {
                                removeInput(id)
                              }}
                            />
                          )
                        }}
                      >
                        <button>
                          <AgentVariableTag agentVariable={agentVariable} />
                        </button>
                      </PopoverTrigger>
                    )
                  })}

                  <PopoverTrigger
                    placement='bottom-start'
                    renderPopover={({ close }) => {
                      return (
                        <WorkflowInputDropdownContent
                          close={(value) => {
                            addUpdateInput(value)
                            close()
                          }}
                        />
                      )
                    }}
                  >
                    {internalWorkflow.variables.length === 0 ? (
                      <Button
                        sentiment='neutral'
                        variant='outlined'
                        size='xsmall'
                        alignment='left'
                      >
                        <PlusSolid />
                        Add input
                      </Button>
                    ) : (
                      <IconButton
                        icon={PlusOutline}
                        sentiment='neutral'
                        variant='muted'
                        size='xsmall'
                      />
                    )}
                  </PopoverTrigger>
                </div>
              </WorkflowSection>

              <WorkflowSection
                title='Instructions'
                description='Tell the AI what to do. Break it up into small steps for the best
            results. Type “@” to reference any inputs, documents, tasks,
            projects or people in Motion.'
              >
                {internalWorkflow.steps.map((step, index, arr) => {
                  const previousStepsAsVariables = arr
                    .slice(0, index)
                    .map((prevStep) => {
                      return {
                        id: createStepVariableId(prevStep.name),
                        type: 'TEXT' as const,
                        description: '',
                        label: prevStep.name,
                        scope: 'STEP' as const,
                        scopeId: prevStep.id,
                        required: true, // Placeholder for now
                      }
                    })

                  const availableVariables = internalWorkflow.variables
                    .concat(previousStepsAsVariables)
                    .concat(allStepsVariables)

                  // Can't use the id as the key because the id is going to change as the step name changes
                  return (
                    <Fragment key={index}>
                      <ConnectedWorkflowStep
                        step={step}
                        inputVariables={availableVariables}
                        onChange={updateStep}
                      />
                      <StepLineSeparator
                        isInBetween={index < arr.length - 1}
                        onClick={() => {
                          addStep(index + 1)
                        }}
                      />
                    </Fragment>
                  )
                })}
              </WorkflowSection>
            </div>
          </main>
        )}
      </div>
    </>
  )
}

function createBlankWorkflow(): AgentWorkflowSchema {
  return {
    id: createPlaceholderId(uniqueId('agent-workflow-')),
    name: '',
    description: '',
    steps: [createBlankStep()],
    variables: [],
  }
}

function createBlankStep(): AgentWorkflowStepSchema {
  return {
    id: uniqueId('blank-workflow-step'),
    type: 'LLM_GENERATION',
    name: '',
    userPrompt: '',
    variables: [],
    destinationStepIds: [],
  }
}
