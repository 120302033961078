import { PlusSolid, PuzzleSolid } from '@motion/icons'
import { type TemplateTaskType } from '@motion/rpc/types'
import { classed } from '@motion/theme'
import { useWillExceedWorkspaceFeatureCap } from '@motion/web-billing'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { TieredPricingUpsellButton } from '~/areas/tiered-pricing/components/tiered-pricing-upsell-button'
import { Outlet, useParams } from 'react-router-dom'

import { EmptyContainer } from './empty-container'

import { LoadingSvg } from '../../../components/Common/Icons/LoadingSvg'
import {
  useDeleteTaskTemplate,
  useTemplatesByWorkspaceId,
} from '../../global/rpc/templates'
import { SettingTableV2 } from '../components'

export function WorkspaceTaskTemplateSettings() {
  const { workspaceId = '' } = useParams<{ workspaceId: string }>()
  const { data: workspaceTemplates, isLoading } = useTemplatesByWorkspaceId(
    {
      workspaceId,
    },
    {
      enabled: !!workspaceId,
    }
  )

  const { mutateAsync: deleteTaskTemplate } = useDeleteTaskTemplate()
  const modalApi = useModalApi()

  const isWorkspaceTaskTemplateCapped = useWillExceedWorkspaceFeatureCap({
    feature: 'templateTasks',
    workspaceId,
    addedUsage: 1,
  })

  const askConfirmation = (item: TemplateTaskType) => {
    return modalApi.prompt('confirm', {
      analytics: {
        name: 'delete-task-template',
      },
      closeButtonText: 'Cancel',
      confirmButtonText: 'Delete template',
      destructive: true,
      title: `Are you sure you want to delete this template ${item.name}?`,
    })
  }

  const handleDeleteTaskTemplate = async (item: TemplateTaskType) => {
    const confirmed = await askConfirmation(item)
    if (confirmed !== ModalDismissed) {
      await deleteTaskTemplate({
        id: item.id,
        workspaceId: item.workspaceId,
      })
    }
  }

  return (
    <Section>
      <div className='mb-6'>
        <div className='flex flex-col gap-2'>
          {isLoading ? (
            <LoadingSvg />
          ) : (
            <div className='flex flex-col gap-2'>
              <SettingTableV2
                title='Task Templates'
                emptyContainer={
                  <EmptyContainer
                    title='No task templates'
                    description='Use templates to easily create common tasks within your workspace.'
                    icon={PuzzleSolid}
                    action={{
                      children: 'Create template',
                      icon: PlusSolid,
                      url: 'task/new',
                    }}
                  />
                }
                items={(workspaceTemplates?.templateTasks ?? []).map(
                  (template) => ({
                    id: template.id,
                    label: template.name,
                    icon: PuzzleSolid,
                    onEditUrl: `task/${template.id}`,
                    onDelete: () => handleDeleteTaskTemplate(template),
                  })
                )}
                headerEndContent={
                  <TieredPricingUpsellButton
                    featureLocked={isWorkspaceTaskTemplateCapped}
                    url={isWorkspaceTaskTemplateCapped ? '' : 'task/new'}
                    size='small'
                    tier='PRO'
                    feature='templateTasks'
                  >
                    Create template
                  </TieredPricingUpsellButton>
                }
              />
            </div>
          )}
        </div>
      </div>
      <Outlet />
    </Section>
  )
}

const Section = classed('section', {
  base: 'flex flex-col gap-8 w-full',
})
