import { AutoscheduleStarGradientSolid, XSolid } from '@motion/icons'
import {
  Button,
  GradientButton,
  IconButton,
  LoadingSpinner,
  Text,
  UnstyledModal,
} from '@motion/ui/base'
import { type AgentWorkflowListingSchema } from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { ErrorBoundary } from '~/global/components'
import { useMyTasksWorkspace } from '~/global/hooks'
import { useAgentWorkflows } from '~/global/rpc'

import { ModalContent, ModalFooter, ModalHeader } from './components'
import { FieldsetContent } from './fields'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'ai-workflow-modal': {
      workflowId?: AgentWorkflowListingSchema['id']
    }
  }
}

type AIWorkflowModalProps = ModalTriggerComponentProps<'ai-workflow-modal'>

export function AIWorkflowModal({ workflowId, close }: AIWorkflowModalProps) {
  return (
    <UnstyledModal
      data-testid='ai-workflow-modal'
      visible
      onClose={close}
      withAnimation
      modalClassName='max-h-full bg-modal-bg border border-semantic-neutral-border-default rounded-lg shadow-lg w-[700px] max-w-[calc(100vw-56px)]'
      overlayClassName='bg-modal-overlay'
    >
      <ErrorBoundary renderFallback={({ error }) => <div>oops</div>}>
        <InnerAIWorkflowModal close={close} workflowId={workflowId} />
      </ErrorBoundary>
    </UnstyledModal>
  )
}

function InnerAIWorkflowModal({
  close,
  workflowId,
}: Omit<AIWorkflowModalProps, 'open'>) {
  // TODO tleunen - hardcoded with specific workspace for now
  const myTasksWorkspace = useMyTasksWorkspace()
  const workspaceId = myTasksWorkspace?.id ?? ''

  const { data: workflows = [], isLoading } = useAgentWorkflows(
    {
      workspaceId,
    },
    { enabled: !!workspaceId }
  )

  return (
    <form
      className='flex flex-col gap-1 max-h-screen'
      onSubmit={(e) => {
        e.preventDefault()
        close()

        // TODO
      }}
    >
      <ModalHeader>
        <Text
          as='h1'
          weight='semibold'
          size='sm'
          className='flex items-center gap-2 text-gradient bg-purple-gradient bg-opacity-100'
        >
          Run AI Workflow
          <AutoscheduleStarGradientSolid
            className='size-4'
            stopColors={['#BC6BFC', '#F147EA']}
          />
        </Text>
        <IconButton
          icon={XSolid}
          sentiment='neutral'
          variant='muted'
          onClick={close}
        />
      </ModalHeader>

      <ModalContent>
        {isLoading ? (
          <div className='h-80 grid place-items-center'>
            <LoadingSpinner />
          </div>
        ) : (
          <FieldsetContent workflows={workflows} modalWorkflowId={workflowId} />
        )}
      </ModalContent>

      <ModalFooter>
        <Button
          sentiment='neutral'
          variant='muted'
          onClick={close}
          shortcut='esc'
        >
          Cancel
        </Button>

        <GradientButton
          type='submit'
          sentiment='purple'
          shortcut='mod+s'
          disabled={isLoading}
        >
          Run workflow
        </GradientButton>
      </ModalFooter>
    </form>
  )
}
