import {
  ChevronDoubleLeftSolid,
  CogSolid,
  QuestionMarkCircleSolid,
  SearchSolid,
} from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { IconButton, Shortcut, Tooltip } from '@motion/ui/base'
import { Label } from '@motion/ui/pm'
import { getWindowData } from '@motion/web-base/env'
import { type User } from '@motion/web-common/auth'
import { useHasTreatment } from '@motion/web-common/flags'

import { useDesktopVersion } from '~/areas/desktop/hooks'

import { UserAccountLink } from './user-account-link'

type SidebarHeaderProps = {
  closeSidebar: () => void
  handleSearch: () => void
  showCloseSidebar: boolean
  toggleIntercom: () => void
  user: User
}

export const SidebarHeader = ({
  closeSidebar,
  handleSearch,
  showCloseSidebar,
  toggleIntercom,
  user,
}: SidebarHeaderProps) => {
  const hasSidebarRevamp = useHasTreatment('revamp-sidebar-search-ui')
  const hasShowDesktopVersion = useHasTreatment('show-desktop-version')
  const desktopVersion = useDesktopVersion()
  const { isElectron } = getWindowData()

  return (
    <div className='self-stretch flex justify-between pr-3 pl-1.5'>
      {!__IS_PROD__ &&
        !!hasShowDesktopVersion &&
        isElectron &&
        desktopVersion && (
          <div className='absolute -top-1 left-3'>
            <Label size='xsmall' color='yellow'>
              {desktopVersion}
            </Label>
          </div>
        )}
      <div className='min-w-0 [&_button]:max-w-full'>
        <UserAccountLink
          user={{
            ...user,
            id: user.uid,
            displayName: user.displayName?.split(' ')[0] || '',
          }}
        />
      </div>

      <div className='flex items-center'>
        <IconButton
          data-testid='navbar-intercom'
          icon={QuestionMarkCircleSolid}
          onClick={toggleIntercom}
          size='small'
          sentiment='neutral'
          variant='muted'
        />

        <IconButton
          data-testid='navbar-settings'
          icon={CogSolid}
          sentiment='neutral'
          size='small'
          url='/web/settings'
          variant='muted'
        />

        {!hasSidebarRevamp && (
          <Tooltip
            asChild
            renderContent={() =>
              templateStr('Search {{shortcut}}', {
                shortcut: <Shortcut shortcut='mod+k' />,
              })
            }
          >
            <IconButton
              icon={SearchSolid}
              onClick={handleSearch}
              sentiment='neutral'
              size='small'
              variant='outlined'
            />
          </Tooltip>
        )}

        {showCloseSidebar && (
          <IconButton
            icon={ChevronDoubleLeftSolid}
            onClick={closeSidebar}
            sentiment='neutral'
            size='small'
            variant='muted'
          />
        )}
      </div>
    </div>
  )
}
