import { DotsHorizontalSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import {
  Button,
  ButtonGroup,
  IconButton,
  SkeletonBodyLines,
  SkeletonLine,
  Text,
} from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'
import { type BaseAgentWorkflowSchema } from '@motion/zod/client'

import { useUriByRouteId } from '~/routing'
import { type ReactNode } from 'react'

export type ConnectedWorkflowCardProps = {
  workflow: BaseAgentWorkflowSchema
}

export function ConnectedWorkflowCard({
  workflow,
}: ConnectedWorkflowCardProps) {
  const getUri = useUriByRouteId()
  const modalApi = useModalApi()

  return (
    <WorkflowCard
      title={workflow.name}
      description={workflow.description}
      editUrl={getUri('ai-workflows-edit', { workflowId: workflow.id })}
      onClickRun={() => {
        modalApi.open('ai-workflow-modal', { workflowId: workflow.id })
      }}
    />
  )
}

export function WorkflowSkeletonCard() {
  return (
    <WorkflowCard
      title={<SkeletonLine className='w-[100px]' />}
      description={<SkeletonBodyLines lines={2} gap='sm' />}
    />
  )
}

type WorkflowCardProps = {
  title: ReactNode
  description: ReactNode
  editUrl?: string
  onClickRun?: () => void
}

function WorkflowCard({
  title,
  description,
  editUrl,
  onClickRun,
}: WorkflowCardProps) {
  return (
    <Wrapper>
      <div className='flex-1 flex flex-col gap-2'>
        <div className='flex flex-row justify-between items-start'>
          <Text weight='semibold' size='sm'>
            {title}
          </Text>
          <IconButton
            icon={DotsHorizontalSolid}
            sentiment='neutral'
            variant='muted'
            size='small'
            disabled={editUrl == null}
          />
        </div>
        {description && (
          <Text size='xs' className='pb-1' truncate>
            {description}
          </Text>
        )}
      </div>
      <ButtonGroup alignment='end'>
        <Button
          sentiment='neutral'
          variant='outlined'
          size='small'
          disabled={editUrl == null}
          url={editUrl}
        >
          Edit
        </Button>
        <Button
          sentiment='primary'
          variant='solid'
          size='small'
          disabled={editUrl == null}
          onClick={onClickRun}
        >
          Run
        </Button>
      </ButtonGroup>
    </Wrapper>
  )
}

const Wrapper = classed('section', {
  base: `
  rounded-lg
  border border-semantic-neutral-border-default
  bg-semantic-neutral-surface-raised-bg-default
  p-4
  w-72
  flex flex-col gap-2
  `,
})
